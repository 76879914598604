export const CODE_OPTIONS = [
  {
      "label": "1510 - Aircraft – Fixed Wing",
      "value": "1510"
  },
  {
      "label": "1520 - Aircraft – Rotary Wing",
      "value": "1520"
  },
  {
      "label": "1540 - Gliders",
      "value": "1540"
  },
  {
      "label": "1550 - Drones",
      "value": "1550"
  },
  {
      "label": "1560 - Airframe Structural Components",
      "value": "1560"
  },
  {
      "label": "1610 - Aircraft Propellers and Components",
      "value": "1610"
  },
  {
      "label": "1615 - Helicopter Rotor Blades, Drive Mechanisms and Components",
      "value": "1615"
  },
  {
      "label": "1620 - Aircraft Landing Gear Components",
      "value": "1620"
  },
  {
      "label": "1630 - Aircraft Wheel and Brake Systems",
      "value": "1630"
  },
  {
      "label": "1640 - Aircraft Control Cable Products",
      "value": "1640"
  },
  {
      "label": "1650 - Aircraft Hydraulic, Vacuum, and De-icing System Components",
      "value": "1650"
  },
  {
      "label": "1660 - Aircraft Air Conditioning, Heating, and Pressurizing Equipment",
      "value": "1660"
  },
  {
      "label": "1670 - Parachutes; Aerial Pick Up, Delivery, Recovery Systems; and Cargo Tie Down Equipment",
      "value": "1670"
  },
  {
      "label": "1680 - Miscellaneous Aircraft Accessories and Components",
      "value": "1680"
  },
  {
      "label": "1740 - Airfield Specialized Trucks and Trailers",
      "value": "1740"
  },
  {
      "label": "1905 - Combat ship and landing vessel",
      "value": "1905"
  },
  {
      "label": "1910 - Transport vessels, passenger & troop",
      "value": "1910"
  },
  {
      "label": "1915 - Cargo & tanker vessels",
      "value": "1915"
  },
  {
      "label": "1920 - Fishing vessels",
      "value": "1920"
  },
  {
      "label": "1925 - Combat ships & landing vessels",
      "value": "1925"
  },
  {
      "label": "1930 - Barges, lighters, cargo",
      "value": "1930"
  },
  {
      "label": "1935 - Barges, lighters, special purpose",
      "value": "1935"
  },
  {
      "label": "1940 - Small Craft",
      "value": "1940"
  },
  {
      "label": "1945 - Pontoons and Floating Docks",
      "value": "1945"
  },
  {
      "label": "1950 - Floating dry docks",
      "value": "1950"
  },
  {
      "label": "1955 - Dredges",
      "value": "1955"
  },
  {
      "label": "1990 - Miscellaneous Vessels",
      "value": "1990"
  },
  {
      "label": "2010 - Ship and Boat Propulsion Components",
      "value": "2010"
  },
  {
      "label": "2020 - Rigging and Rigging Gear",
      "value": "2020"
  },
  {
      "label": "2030 - Deck Machinery",
      "value": "2030"
  },
  {
      "label": "2040 - Marine Hardware and Hull Items",
      "value": "2040"
  },
  {
      "label": "2050 - Buoys",
      "value": "2050"
  },
  {
      "label": "2060 - Commercial Fishing Equipment",
      "value": "2060"
  },
  {
      "label": "2090 - Miscellaneous Ship and Marine Equipment",
      "value": "2090"
  },
  {
      "label": "2305 - Ground effect vehicles",
      "value": "2305"
  },
  {
      "label": "2310 - Passenger Motor Vehicles",
      "value": "2310"
  },
  {
      "label": "2320 - Trucks and tractors",
      "value": "2320"
  },
  {
      "label": "2330 - Trailers",
      "value": "2330"
  },
  {
      "label": "2340 - Motocycles, motor scooters, bicycles",
      "value": "2340"
  },
  {
      "label": "2410 - Tractor, full track, low speed",
      "value": "2410"
  },
  {
      "label": "2420 - Tractor, wheeled",
      "value": "2420"
  },
  {
      "label": "2430 - Tractor, full track, high speed",
      "value": "2430"
  },
  {
      "label": "2610 - Tires and Tubes, Except Aircraft",
      "value": "2610"
  },
  {
      "label": "2630 - Tires, Solid and Cushion",
      "value": "2630"
  },
  {
      "label": "2640 - Tires and Tubes, Aircraft",
      "value": "2640"
  },
  {
      "label": "2640 - Tire Rebuilding and Tire and Tube Repair Materials",
      "value": "2640"
  },
  {
      "label": "2810 - Gasoline Reciprocating Engines, Aircraft Prime Mover; and Components",
      "value": "2810"
  },
  {
      "label": "2840 - Gas Turbines and Jet Engines, Aircraft Prime Moving; and Components",
      "value": "2840"
  },
  {
      "label": "3438 - Miscellaneous Welding Equipment",
      "value": "3438"
  },
  {
      "label": "3439 - Miscellaneous Welding, Soldering, and Brazing Supplies and Accessories",
      "value": "3439"
  },
  {
      "label": "3510 - Laundry & Dry Cleaning Equipment",
      "value": "3510"
  },
  {
      "label": "3520 - Shoe Repairing Equipment",
      "value": "3520"
  },
  {
      "label": "3530 - Industrial Sewing Machines and Mobile Textile Repair Shops",
      "value": "3530"
  },
  {
      "label": "3540 - Wrapping and Packaging Machinery",
      "value": "3540"
  },
  {
      "label": "3550 - Vending and Coin Operated Machines",
      "value": "3550"
  },
  {
      "label": "3590 - Micellaneious Serice and Trade Equipment",
      "value": "3590"
  },
  {
      "label": "3605 - Food Products, Machinery, and Equipment",
      "value": "3605"
  },
  {
      "label": "3610 - Printing, Duplicating, and Bookbinding Equipment",
      "value": "3610"
  },
  {
      "label": "3611 - Industrial Marking Machines",
      "value": "3611"
  },
  {
      "label": "3615 - Pulp and Paper Industrial Machinery",
      "value": "3615"
  },
  {
      "label": "3620 - Rubber and Plastic Working Machinery",
      "value": "3620"
  },
  {
      "label": "3625 - Textile Industries Machinery",
      "value": "3625"
  },
  {
      "label": "3630 - Clay and Concrete Products Industrial Machinery",
      "value": "3630"
  },
  {
      "label": "3635 - Crystal and Glass Industries Machinery",
      "value": "3635"
  },
  {
      "label": "3710 - Soil Preparation Equipment",
      "value": "3710"
  },
  {
      "label": "3720 - Harvesting Equipment",
      "value": "3720"
  },
  {
      "label": "3730 - Dairy, Poultry and Livestock Equipment",
      "value": "3730"
  },
  {
      "label": "3740 - Pest, Disease and Frost Control Equipment",
      "value": "3740"
  },
  {
      "label": "3750 - Gardening Implements and Tools",
      "value": "3750"
  },
  {
      "label": "3770 - Saddlery, Harness Whips and Related Animal Furnishings",
      "value": "3770"
  },
  {
      "label": "3805 - Earth moving and excavating equipment",
      "value": "3805"
  },
  {
      "label": "3810 - Cranes and crane-shovels",
      "value": "3810"
  },
  {
      "label": "3815 - Crane and crane shovel attachments",
      "value": "3815"
  },
  {
      "label": "3820 - Mining, rock drilling, earth boring, & related equipment",
      "value": "3820"
  },
  {
      "label": "3825 - Road clearing, cleaning, & marketing equipment",
      "value": "3825"
  },
  {
      "label": "3830 - Truck and Tractor attachments",
      "value": "3830"
  },
  {
      "label": "3930 - Warehouse Trucks and Tractors",
      "value": "3930"
  },
  {
      "label": "4210 - Fire Fighting Equipment",
      "value": "4210"
  },
  {
      "label": "4240 - Safety and Rescue Equipment",
      "value": "4240"
  },
  {
      "label": "5110 - Hand Tools, Edged, Nonpowered",
      "value": "5110"
  },
  {
      "label": "5120 - Hand Tools, Nonedged, Nonpowered",
      "value": "5120"
  },
  {
      "label": "5130 - Hand Tools, Power Driven",
      "value": "5130"
  },
  {
      "label": "5133 - Drill Bits",
      "value": "5133"
  },
  {
      "label": "5140 - Tool and Hardware Boxes",
      "value": "5140"
  },
  {
      "label": "5210 - Measuring Tools",
      "value": "5210"
  },
  {
      "label": "5305 - Screws",
      "value": "5305"
  },
  {
      "label": "5306 - Bolts",
      "value": "5306"
  },
  {
      "label": "5307 - Studs",
      "value": "5307"
  },
  {
      "label": "5310 - Nuts and Washers",
      "value": "5310"
  },
  {
      "label": "5315 - Nails, Machine Keys, and Pins",
      "value": "5315"
  },
  {
      "label": "5320 - Rivets",
      "value": "5320"
  },
  {
      "label": "5330 - Packing and Gasket Materials",
      "value": "5330"
  },
  {
      "label": "5340 - Hardware",
      "value": "5340"
  },
  {
      "label": "5341 - Brackets",
      "value": "5341"
  },
  {
      "label": "5365 - Bushings, Rings, Shims, and Spacers",
      "value": "5365"
  },
  {
      "label": "5440 - Scaffolding Equipment and Concrete Forms",
      "value": "5440"
  },
  {
      "label": "5680 - Miscellaneous Construction Materials",
      "value": "5680"
  },
  {
      "label": "5805 - Telephone and Telegraph Equipment",
      "value": "5805"
  },
  {
      "label": "5810 - Communications Secruity Equipment and Components",
      "value": "5810"
  },
  {
      "label": "5811 - Other Cryptologic Equipment and Components",
      "value": "5811"
  },
  {
      "label": "5815 - Teletype and Facsmile Equipment",
      "value": "5815"
  },
  {
      "label": "5820 - Radio and Television Communication Equipment, Except Airborne",
      "value": "5820"
  },
  {
      "label": "5821 - Radio and Television Communication Equipment, Airborne",
      "value": "5821"
  },
  {
      "label": "5825 - Radio navigation equipment, except airborne",
      "value": "5825"
  },
  {
      "label": "5826 - Radio navigation equipment, airborne",
      "value": "5826"
  },
  {
      "label": "5830 - Intercommunication and Public Address Systems, Except Airborne",
      "value": "5830"
  },
  {
      "label": "5831 - Intercommunication and Public Address Systems, Airborne",
      "value": "5831"
  },
  {
      "label": "5835 - Sound Recording and Reproducing Equipment",
      "value": "5835"
  },
  {
      "label": "5835 - Sound recording and reproducing equipment",
      "value": "5835"
  },
  {
      "label": "5836 - Video Recording and Reproducing Equipment",
      "value": "5836"
  },
  {
      "label": "5836 - Video recording and reproduction equipment",
      "value": "5836"
  },
  {
      "label": "5840 - Radar equipment, except airborne",
      "value": "5840"
  },
  {
      "label": "5841 - Radar equipment, airborne",
      "value": "5841"
  },
  {
      "label": "5845 - Underwater sound equipment",
      "value": "5845"
  },
  {
      "label": "5850 - Visible and invisible light communication equipment",
      "value": "5850"
  },
  {
      "label": "5855 - Night vision equipment, emitted and reflected radiation",
      "value": "5855"
  },
  {
      "label": "5860 - Stimulated Coherent Radiation Devices, Components, and Accessories",
      "value": "5860"
  },
  {
      "label": "5865 - Electronic Countermesaures, Couter-Countermesaures, and Quick Reactive",
      "value": "5865"
  },
  {
      "label": "5895 - Miscellaneous Communication Equipment",
      "value": "5895"
  },
  {
      "label": "5905 - Resistors",
      "value": "5905"
  },
  {
      "label": "5910 - Capacitors",
      "value": "5910"
  },
  {
      "label": "5915 - Filters and networks",
      "value": "5915"
  },
  {
      "label": "5920 - Fuses, arrestors, absorbers, and protectors",
      "value": "5920"
  },
  {
      "label": "5925 - Circuit breakers",
      "value": "5925"
  },
  {
      "label": "5930 - Switches",
      "value": "5930"
  },
  {
      "label": "5935 - Connectors, electrical",
      "value": "5935"
  },
  {
      "label": "5940 - Lugs, terminals, and terminal strips",
      "value": "5940"
  },
  {
      "label": "5945 - Relays and Solenoids",
      "value": "5945"
  },
  {
      "label": "5950 - Coils and transformers",
      "value": "5950"
  },
  {
      "label": "5955 - Oscillators and piezoelectric crystals",
      "value": "5955"
  },
  {
      "label": "5960 - Electron tubes and associated hardware",
      "value": "5960"
  },
  {
      "label": "5961 - Semiconductor devices and associated hardware",
      "value": "5961"
  },
  {
      "label": "5962 - Microcircuits, electronic",
      "value": "5962"
  },
  {
      "label": "5963 - Electronic modules",
      "value": "5963"
  },
  {
      "label": "5965 - Headsets, handsets, microphones and speakers",
      "value": "5965"
  },
  {
      "label": "5970 - Electrical insulators and insulating materials",
      "value": "5970"
  },
  {
      "label": "5975 - Electrical hardware and supplies",
      "value": "5975"
  },
  {
      "label": "5998 - Electrical and electronic assemblies, boards, cards, & associated hardware",
      "value": "5998"
  },
  {
      "label": "6115 - Generators, Generator Set, Electrical",
      "value": "6115"
  },
  {
      "label": "6505 - Drugs and biologicals",
      "value": "6505"
  },
  {
      "label": "6508 - Medicated cosmetics and toiletries",
      "value": "6508"
  },
  {
      "label": "6515 - Medical and surgical instruments, equipment, and supplies",
      "value": "6515"
  },
  {
      "label": "6520 - Dental instruments, equipment, and supplies",
      "value": "6520"
  },
  {
      "label": "6525 - X-ray equipment and supplies: medical, dental, veterinary",
      "value": "6525"
  },
  {
      "label": "6530 - Hospital furniture, equipment, utensils, and supplies",
      "value": "6530"
  },
  {
      "label": "6532 - Hospital and surgical clothing and related special purpose items",
      "value": "6532"
  },
  {
      "label": "6540 - Ophthalmic instruments, equipment, and supplies",
      "value": "6540"
  },
  {
      "label": "6545 - Replenishable field medical sets, kits, and outfits",
      "value": "6545"
  },
  {
      "label": "6550 - In Vitro diagnostic substances, reagents, test kits and sets",
      "value": "6550"
  },
  {
      "label": "6625 - Electrical and electronic properties measuring & testing instruments",
      "value": "6625"
  },
  {
      "label": "6630 - Chemical analysis equipment",
      "value": "6630"
  },
  {
      "label": "6635 - Physical properties testing equipment",
      "value": "6635"
  },
  {
      "label": "6636 - Environmental cambers and related equipment",
      "value": "6636"
  },
  {
      "label": "6640 - Laboratory equipment & supplies",
      "value": "6640"
  },
  {
      "label": "6645 - Time measuring instrument",
      "value": "6645"
  },
  {
      "label": "6650 - Optical instruments, test equipment",
      "value": "6650"
  },
  {
      "label": "6655 - Geophysical instruments",
      "value": "6655"
  },
  {
      "label": "6660 - Meteorological instruments and apparatus",
      "value": "6660"
  },
  {
      "label": "6665 - Hazard-detecting instruments and apparatus",
      "value": "6665"
  },
  {
      "label": "6670 - Scales and balances",
      "value": "6670"
  },
  {
      "label": "6675 - Drafting, surveying, and mapping instruments",
      "value": "6675"
  },
  {
      "label": "6680 - Liquid and gas flow, liquid level, and mechanical motion measuring instruments",
      "value": "6680"
  },
  {
      "label": "6685 - Pressure, temperature, and humidity measuring and controlling instruments",
      "value": "6685"
  },
  {
      "label": "6695 - Combination and miscellaneous instruments",
      "value": "6695"
  },
  {
      "label": "6710 - Cameras, motion picture",
      "value": "6710"
  },
  {
      "label": "6720 - Cameras, still picture",
      "value": "6720"
  },
  {
      "label": "6730 - Photographic supplies",
      "value": "6730"
  },
  {
      "label": "6760 - Photographic equipment and accessories",
      "value": "6760"
  },
  {
      "label": "6770 - Film, processed",
      "value": "6770"
  },
  {
      "label": "6780 - Photographic sets, kits, and outfits",
      "value": "6780"
  },
  {
      "label": "7010 - ADPE system configuration",
      "value": "7010"
  },
  {
      "label": "7020 - ADP central processing unit (CPU, computer), analog",
      "value": "7020"
  },
  {
      "label": "7021 - ADP central processing unit (CPU, computer), digital",
      "value": "7021"
  },
  {
      "label": "7022 - ADP central processing unit (CPU, computer), hybrid",
      "value": "7022"
  },
  {
      "label": "7025 - ADP input/output and storage devices",
      "value": "7025"
  },
  {
      "label": "7030 - ADP software",
      "value": "7030"
  },
  {
      "label": "7035 - ADP support equipment",
      "value": "7035"
  },
  {
      "label": "7040 - Punched card equipment",
      "value": "7040"
  },
  {
      "label": "7042 - Mini and Microcomputer control devices",
      "value": "7042"
  },
  {
      "label": "7045 - ADP supplies",
      "value": "7045"
  },
  {
      "label": "7050 - ADP components",
      "value": "7050"
  },
  {
      "label": "7105 - Household Furniture",
      "value": "7105"
  },
  {
      "label": "7110 - Office Furniture",
      "value": "7110"
  },
  {
      "label": "7125 - Cabinets, Lockers, Bins, and Shelving",
      "value": "7125"
  },
  {
      "label": "7195 - Miscellaneous Furniture and Fixtures",
      "value": "7195"
  },
  {
      "label": "7210 - Household Furnishings",
      "value": "7210"
  },
  {
      "label": "7220 - Floor Coverings",
      "value": "7220"
  },
  {
      "label": "7230 - Draperies, Awnings and Shades",
      "value": "7230"
  },
  {
      "label": "7240 - Household and Commercial Utility Containers",
      "value": "7240"
  },
  {
      "label": "7290 - Miscellaneous Household and Commercial Furnishings and Appliances",
      "value": "7290"
  },
  {
      "label": "7310 - Food Cooking, Baking, and Serving Equipment",
      "value": "7310"
  },
  {
      "label": "7320 - Kitchen Equipment and Appliances",
      "value": "7320"
  },
  {
      "label": "7330 - Kitchen Hand Tool and Utensils",
      "value": "7330"
  },
  {
      "label": "7340 - Cutlery and Flatware",
      "value": "7340"
  },
  {
      "label": "7350 - Tableware",
      "value": "7350"
  },
  {
      "label": "7360 - Sets, Kits, Outfits and Modules, Food Preparation and Serving",
      "value": "7360"
  },
  {
      "label": "7420 - Accounting & Calculating Machines",
      "value": "7420"
  },
  {
      "label": "7430 - Typewriters & office type composing machines",
      "value": "7430"
  },
  {
      "label": "7435 - Office Information Systems equipment",
      "value": "7435"
  },
  {
      "label": "7450 - Office type sound recording & reproducing machines",
      "value": "7450"
  },
  {
      "label": "7460 - Visible Record Machines",
      "value": "7460"
  },
  {
      "label": "7490 - Miscellaneous Office Machines",
      "value": "7490"
  },
  {
      "label": "7510 - Office supplies",
      "value": "7510"
  },
  {
      "label": "7520 - Office Devices & Accessories",
      "value": "7520"
  },
  {
      "label": "7530 - Stationery & Record Forms",
      "value": "7530"
  },
  {
      "label": "7540 - Standard Forms",
      "value": "7540"
  },
  {
      "label": "7710 - Musical Instruments",
      "value": "7710"
  },
  {
      "label": "7720 - Musical Instrument Parts and Accessories",
      "value": "7720"
  },
  {
      "label": "7730 - Phonographs, Radios, and Television Sets, Home Type",
      "value": "7730"
  },
  {
      "label": "7735 - Parts and Accessories of Phonographs, Radios, and Television Set",
      "value": "7735"
  },
  {
      "label": "7740 - Phonograph Records",
      "value": "7740"
  },
  {
      "label": "7810 - Athletic and Sporting Equipment",
      "value": "7810"
  },
  {
      "label": "7820 - Games, Toys and Wheeled Goods",
      "value": "7820"
  },
  {
      "label": "7830 - Recreational and Gymnastic Equipment",
      "value": "7830"
  },
  {
      "label": "7910 - Floor Polishers and Vacuum Cleaning Equipment",
      "value": "7910"
  },
  {
      "label": "7920 - Brooms, Brushes, Mops and Sponges",
      "value": "7920"
  },
  {
      "label": "7930 - Cleaning and Polishing Compounds and Preparations",
      "value": "7930"
  },
  {
      "label": "8315 - Clothing, Special Purpose",
      "value": "8315"
  },
  {
      "label": "8405 - Outerwear, Men’s",
      "value": "8405"
  },
  {
      "label": "8410 - Outerwear, Women’s",
      "value": "8410"
  },
  {
      "label": "8415 - Clothing, Special Purpose",
      "value": "8415"
  },
  {
      "label": "8420 - Underwear and Nightwear, Men’s",
      "value": "8420"
  },
  {
      "label": "8425 - Underwear and Nightwear, Women’s",
      "value": "8425"
  },
  {
      "label": "8430 - Footwear, Men’s",
      "value": "8430"
  },
  {
      "label": "8435 - Footwear, Women’s",
      "value": "8435"
  },
  {
      "label": "8440 - Hosiery, Hand wear, Clothing Accessories, Men’s",
      "value": "8440"
  },
  {
      "label": "8445 - Hosiery, Hand wear, Clothing Accessories, Women’s",
      "value": "8445"
  },
  {
      "label": "8450 - Children’s and Infants’ Apparel and Accessories",
      "value": "8450"
  },
  {
      "label": "8455 - Badges and Insignia",
      "value": "8455"
  },
  {
      "label": "8460 - Luggage",
      "value": "8460"
  },
  {
      "label": "8465 - Individual Equipment",
      "value": "8465"
  },
  {
      "label": "8510 - Perfumes, Toilet Preparation, and Powders",
      "value": "8510"
  },
  {
      "label": "8520 - Toilet Soap, Shaving Preparations, and Dentifrices",
      "value": "8520"
  },
  {
      "label": "8530 - Personal Toiletry Articles",
      "value": "8530"
  },
  {
      "label": "8540 - Toiletry Paper Products",
      "value": "8540"
  },
  {
      "label": "8710 - Forage and Feed",
      "value": "8710"
  },
  {
      "label": "8720 - Fertilizers",
      "value": "8720"
  },
  {
      "label": "8730 - Seeds and Nursery Stock",
      "value": "8730"
  },
  {
      "label": "8810 - Live Animals, Raised for Food",
      "value": "8810"
  },
  {
      "label": "8820 - Live Animals, Not Raised for Food",
      "value": "8820"
  },
  {
      "label": "9910 - Jewelry",
      "value": "9910"
  },
  {
      "label": "9915 - Collectors’ and/or Historical Items",
      "value": "9915"
  }
]