import { SelectCodes } from './SelectCodes/SelectCodes';
import { SelectCategories } from './SelectCategories/SelectCategories';
import { EmailForm } from './EmailForm/EmailForm';
import { ContactForm } from './ContactForm/ContactForm';
import { Results } from './Results/Results';
import SubscribePage from './SubscribePage/SubscribePage';

export const SelectedStep = ({ activeStep, onClickNext }) => { 
  if (activeStep === 1) {
    return <SelectCodes onClickNext={onClickNext} />;
  }
  if (activeStep === 2) {
    return <SelectCategories onClickNext={onClickNext} />;
  }
  if (activeStep === 3) {
    return <EmailForm onClickNext={onClickNext} />;
  }
  if (activeStep === 4) {
    return <Results onClickNext={onClickNext} />;
  }
  if (activeStep === 5) {
    return <ContactForm onClickNext={onClickNext} />;
  }
  if (activeStep === 6) {
    return <SubscribePage onClickNext={onClickNext} />;
  }
  
}