// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loading-screen-title {
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.loading-screen-subtext {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/StepsSection/LoadingScreen/LoadingScreen.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,+BAA+B;EAC/B,gBAAgB;AAClB","sourcesContent":["\n.loading-screen-title {\n  margin-bottom: 1rem;\n  font-family: 'Open Sans', sans-serif;\n}\n\n.loading-screen-subtext {\n  margin-top: 2rem;\n  font-weight: bold;\n  font-size: 18px;\n  font-family: \"Lato\", sans-serif;\n  line-height: 1.6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
