import loadingGif from '../../images/1480.gif';
import './LoadingScreen.css';

export const LoadingScreen = () => { 
  return (
    <div className="loading-screen">
      <h2 className="loading-screen-title">DIBBs Daily Opportunity Questionnaire</h2>
      <img src={loadingGif} alt="loader" className="loader" width={200} height={200} />
      <p className="loading-screen-subtext">
        We are pulling the opportunities at this moment.<br />
        This may take 2 to 3 minutes.<br />
        We appreciate your patience.
      </p>
    </div>
  );
}