import { createSlice } from '@reduxjs/toolkit'

export const SelectCategoriesSlice = createSlice({
  name: 'SelectCategories',
  initialState: {
    selectedValues: []
  },
  reducers: {
    setSelectedCategories: (state, action) => {
      const { name, checked } = action.payload;
      // if(checked) {
        state.selectedValues = {
          ...state.selectedValues,
          [name]: checked,
        }
      // } else {
      //   delete state.selectedValues[name];
      // }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedCategories } = SelectCategoriesSlice.actions

export default SelectCategoriesSlice.reducer