// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.select-categories-title {
  margin-bottom: 1rem;
  font-family: 'Lato', sans-serif;
}

.select-categories-subtext {
  margin-top: 0;
  font-size: 20px;
  color: #6B6B6B;
  font-family: "Lato", sans-serif;
  margin-bottom: 1rem;
}


.checkbox-list {
  text-align: left; /* Align text to the left */
  padding: 0; /* Remove padding */
  margin: 20px 0; /* Margin around the form for better spacing */
}

.checkbox-item {
  display: flex;
  margin: 1.5rem 0;
  font-size: 16px;
  color: #6B6B6B;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
}

.checkbox-item-input {
  height: 1rem;
  width: 1rem;
}

.checkbox-item-image {
  margin-left: 0.5rem;
}

input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label text */
}

.small-line{
  width: 16%;
  height: 2px;
  background-color: rgb(73, 69, 69);
  align-items: center;
  margin-top: -.8rem;
  justify-content: center;
}


/* Mobile-Styling */

@media (max-width: 480px) {
  .small-line {
    width: 35%;
  }
}`, "",{"version":3,"sources":["webpack://./src/StepsSection/SelectCategories/SelectCategories.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,cAAc;EACd,+BAA+B;EAC/B,mBAAmB;AACrB;;;AAGA;EACE,gBAAgB,EAAE,2BAA2B;EAC7C,UAAU,EAAE,mBAAmB;EAC/B,cAAc,EAAE,8CAA8C;AAChE;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,+BAA+B;EAC/B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB,EAAE,0CAA0C;AAChE;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iCAAiC;EACjC,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;;AAGA,mBAAmB;;AAEnB;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["\n.select-categories-title {\n  margin-bottom: 1rem;\n  font-family: 'Lato', sans-serif;\n}\n\n.select-categories-subtext {\n  margin-top: 0;\n  font-size: 20px;\n  color: #6B6B6B;\n  font-family: \"Lato\", sans-serif;\n  margin-bottom: 1rem;\n}\n\n\n.checkbox-list {\n  text-align: left; /* Align text to the left */\n  padding: 0; /* Remove padding */\n  margin: 20px 0; /* Margin around the form for better spacing */\n}\n\n.checkbox-item {\n  display: flex;\n  margin: 1.5rem 0;\n  font-size: 16px;\n  color: #6B6B6B;\n  font-family: 'Lato', sans-serif;\n  cursor: pointer;\n}\n\n.checkbox-item-input {\n  height: 1rem;\n  width: 1rem;\n}\n\n.checkbox-item-image {\n  margin-left: 0.5rem;\n}\n\ninput[type=\"checkbox\"] {\n  margin-right: 10px; /* Space between checkbox and label text */\n}\n\n.small-line{\n  width: 16%;\n  height: 2px;\n  background-color: rgb(73, 69, 69);\n  align-items: center;\n  margin-top: -.8rem;\n  justify-content: center;\n}\n\n\n/* Mobile-Styling */\n\n@media (max-width: 480px) {\n  .small-line {\n    width: 35%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
