import { configureStore } from '@reduxjs/toolkit';
import codesSlice from './StepsSection/SelectCodes/SelectCodesSlice';
import categoriesSlice from './StepsSection/SelectCategories/SelectCategoriesSlice';
import emailSlice from './StepsSection/EmailForm/EmailFormSlice';
import contactSlice from './StepsSection/ContactForm/ContactFormSlice';
import resultsSlice from './StepsSection/Results/ResultsSlice';

export default configureStore({
  reducer: {
    codes: codesSlice,
    categories: categoriesSlice,
    email: emailSlice,
    contact: contactSlice,
    results: resultsSlice,
  }
})