import './NavBarStyles.css';

export const NavBar = ({ activeStep }) => (
    <div className="progress-bar">
    {
      [1, 2, 3, 4, 5, 6].map((step) => (
        <div 
          key={step}
          className={`circle ${activeStep === step ? 'active' : '' } ${ step < activeStep ? 'disabled' : '' }`}
        >
          {step}
        </div>
      ))
    }
  </div>
);
