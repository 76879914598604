import { useEffect, useState } from 'react';
import { NextButton } from '../../NextButton';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';
import './Results.css';
import { useSelector } from 'react-redux';
import { CATEGORIES_OPTIONS } from '../SelectCategories/categoriesOptions';

export const Results = ({ onClickNext }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setData] = useState({});
  const selectedOption = useSelector(state => state.codes.selectedOption);
  const selectedValues = useSelector((state) => state.categories.selectedValues);

  let TotalOpportunities = 0;
  useEffect(() => {
    fetch('https://api.govscraper.com/process/get_samples/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        "fscs": selectedOption.map(option => option.value),
        "contract_types": Object.keys(selectedValues)
      })
    })
    .then(response => response.json())
    .then(data => {
      setData(data);
    })
    .catch(error => {
      console.error('I Errored out:', error);
    }).finally(() => {
      setIsLoading(false);
    })
  }, [selectedOption, selectedValues]);

  return isLoading ? <LoadingScreen /> : (
    <>
      <h2 className="result-screen-title">DIBBs Daily Opportunity Questionnaire</h2>
      <h3 className="result-screen-subtext">Your Opportunities - Previous 7 days</h3>
      <div className="result-container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {Object.keys(results).length > 0 ? (
  <>
    {Object.keys(results).map((resultKey, index) => {
      const resultOption = CATEGORIES_OPTIONS.find(obj => obj.code === resultKey);
      TotalOpportunities = TotalOpportunities+results[resultKey];
      return resultOption ? (
        <label className="result-item" key={index}>
          <span className="result-item-count"> {results[resultKey]} </span>
         <div>
          {resultOption.label}
          <img 
            className="result-item-image" 
            src={resultOption.image} 
            alt={resultOption.label || 'Category image'} 
            height={20} 
          />
         </div>
        </label>
      ) : {};
    })}
    <div style={{width: "70%", display: "flex", flexDirection: "column", gap: 10}}>
      <div className='total-line'></div>
      <div style={{display:'flex', justifyContent:'flex-start', alignItems:'baseline'}}>
        <div className="result-item-count-total">{TotalOpportunities}</div>
        <div className="result-item-total">Total Opportunities Over The Last 7 Days</div>
      </div>

    </div>
  </>
) : (
<span>
<div className='no-opportunities-line'></div>
<p className="no-opportunities-title">No opportunities this week, but…</p>
<p className="no-opportunities-content">On average, the DIBBS posts over 200 opportunities each week!</p>
</span>
)}
      </div>
      <NextButton className="result-screen-next-button" buttonText="Gain Access to These Opportunities and More" handleClick={onClickNext} />
    </>
  )
};
