import { useState } from 'react';
import { NextButton } from '../../NextButton';
import './ContactForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { setF_name } from './ContactFormSlice';

export const ContactForm = ({onClickNext}) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    company_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: ''
  });
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.email);

  const handleChange = (event) => { 
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (formError[name] && value) {
      setFormError({
        ...formError,
        [name]: ''
      });
    }
  }
  
  const handleNameChange = (event) => { 
    const { name, value } = event.target;
    const nameRegex = /^[a-zA-Z]+$/;
    if (!nameRegex.test(value)) {
      setFormError({ ...formError, [name]: `Please enter a valid ${name.replace(/_/g, ' ')}.`});
    } else {
      setFormError({ ...formError, [name]: ''});

    }
    if(name === 'first_name')
    {
      dispatch(setF_name(value));
    }
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handlePhoneChange = (event) => {
    const { name, value } = event.target;
  
    // Filter out non-numeric characters
    let cleanValue = value.replace(/[^0-9]/g, '');
    
    //`clean value` should have only 10 number
    if(cleanValue.length > 10){
      cleanValue  = cleanValue.slice(0,-1);
    }

    let formattedNumber = '';
    // Format the phone number as (xxx) xxx-xxxx
    if (cleanValue.length <= 3) {
      formattedNumber = cleanValue;
    } else if (cleanValue.length <= 6) {
      formattedNumber = `(${cleanValue.slice(0, 3)}) ${cleanValue.slice(3)}`;
    } else if (cleanValue.length <= 10) {
      formattedNumber = `(${cleanValue.slice(0, 3)}) ${cleanValue.slice(3, 6)}-${cleanValue.slice(6)}`;
    } else {
      formattedNumber = `(${cleanValue.slice(0, 3)}) ${cleanValue.slice(3, 6)}-${cleanValue.slice(6, 10)}`;
    }

    // Validate phone number (optional)
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(cleanValue) || cleanValue.length > 10) {
      setFormError({ ...formError, phone: 'Please enter a valid contact number.' });
    } else {
      setFormError({ ...formError, phone: '' });
    }
  
    // Update form data
    setFormData({
      ...formData,
      [name]: formattedNumber
    });
  };
  

  const handleZipChange = (event) => { 
    const { value } = event.target;
    validateZip(value);
    setFormData({
      ...formData,
      zip_code: value
    });
  }

  const validateZip = (zipValue) => {
    const regex = /^\d{5}(?:-\d{4})?$/
    
    if (!regex.test(zipValue)) {
      setFormError({ ...formError, zip_code: 'Please enter a valid 5-digit ZIP code.'});
    } else {
      setFormError({ ...formError, zip_code: ''});
    }
  }

  const isValidFormData = (data) => {
    const { address2, ...requiredData } = data;
    if (Object.values(requiredData).some(value => !value)) { 
      const error = {};
      Object.entries(data).forEach(([key, value]) => {
        if (!value) {
          error[key] = 'This field is required.';
        }
      });
      setFormError(error);
      return false;
    }
    return true
  }
  const isNextDisabled = Object.values(formData).filter(value => !!value)?.length < 7 && Object.values(formError).filter(value => !!value)?.length > 0;

  const handleClick = () => {
    const isValid = isValidFormData(formData);
    if (!isValid) {
      return;
    }

    const hasErrors = Object.values(formError).some(value => !!value);
    if (hasErrors) {
      return;
    }


    const { first_name, last_name, phone, company_name, address1, address2, city, state, zip_code } = formData;
    fetch(`https://api.govscraper.com/user/update/${userId}`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        first_name,
        last_name,
        phone,
        company_name,
        address: `${address1} ${address2}`,
        city,
        state,
        zip_code
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      onClickNext();
    })
    .catch(error => {
      console.error('I Errored out:', error);
    })

  }

  const FORM_PARTS = [
    { name: 'first_name', placeholder: 'First Name', onChange: handleNameChange },
    { name: 'last_name', placeholder: "Last Name", onChange: handleNameChange },
    { name: 'phone', placeholder: 'Phone Number', onChange: handlePhoneChange },
    { name: 'company_name', placeholder: 'Company Name', onChange: handleChange },
    { name: 'address1', placeholder: 'Business Address Line #1', onChange: handleChange },
    { name: 'address2', placeholder: 'Business Address Line #2', onChange: handleChange },
    { name: 'city', placeholder: 'City', onChange: handleChange },
    { name: 'state', placeholder: 'State', onChange: handleChange },
    { name: 'zip_code', placeholder: 'Zip Code', onChange: handleZipChange },
  ]
  return (
    <>
      <h2 className="contact-form-title">Complete to See Your Results</h2>
      <p className="contact-form-subtext">Please provide your contact details</p>
      <form className="contact-form-container">
        {FORM_PARTS.map((part, index) => (
          <div key={index} className="contact-form-input-container">
            <input key={index} className="contact-form-input" value={formData[part.name]} name={part.name} onChange={part.onChange ?? handleChange} type="text" placeholder={part.placeholder} />
            {formError[part.name] && <p className="contact-form-error">{formError[part.name]}</p>}
          </div> 
         
        ))}
      </form>
      <NextButton buttonText="Proceed to Payment" handleClick={handleClick} disabled={isNextDisabled} />
    </>
  )
};