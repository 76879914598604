import { createSlice } from '@reduxjs/toolkit'

export const EmailFormSlice = createSlice({
  name: 'emailForm',
  initialState: {
    userId: ""
  },
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setProcessId: (state, action) => {
      state.processId = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setUserId, setProcessId } = EmailFormSlice.actions

export default EmailFormSlice.reducer