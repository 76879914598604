import { useState } from 'react';
import { NextButton } from '../../NextButton';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId, setProcessId } from './EmailFormSlice';
import './EmailForm.css';

export const EmailForm = ({ onClickNext }) => {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();
  const selectedOption = useSelector(state => state.codes.selectedOption);
  const selectedValues = useSelector((state) => state.categories.selectedValues);

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleChange = (event) => {
    setEmail(event.target.value);
    setValid(isValidEmail(event.target.value));
  }


  const handleClick = () => {
    fetch('https://api.govscraper.com/user/create/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ email })
    })
    .then(response => response.json())
    .then(data => {
      dispatch(setUserId(data.id));
      fetch('https://api.govscraper.com/process/create/', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          user_id: data.id,
          frequency: "m",
          fscs: selectedOption.map(option => option.value),
          contract_types: Object.keys(selectedValues),
          "start_date": "2024-07-25T03:47:02.208Z",
          "end_date": "2024-07-25T03:47:02.208Z",
          "last_update_date": "2024-07-25T03:47:02.208Z",
          "next_update_date": "2024-07-25T03:47:02.208Z",
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('I got the data:', data);
        dispatch(setProcessId(data.id));
        onClickNext();
      })
      .catch(error => {
        console.error('I Errored out:', error);
      })
    })
    .catch(error => {
      console.error('I Errored out:', error);
    })
  }
  
  return (
    <div className="email-form-container">
      <h2 className="email-form-title">DIBBs Daily Opportunity Questionnaire</h2>
      <p className="email-form-subtext">Each day you'll receive a collection of the opportunities, what is the best email address to send the opportunities to?</p>
      <input value={email} onChange={handleChange} className="email-form-input" type="email" placeholder="Enter Email Address" />
      <NextButton buttonText="Move to Step 4 >" handleClick={handleClick} disabled={!valid} />
    </div>
  )
};