import { createSlice } from '@reduxjs/toolkit'

export const ContactFormSlice = createSlice({
  name: 'contactForm',
  initialState: {
    f_name: ','
  },
  reducers: {
    setF_name: (state, action) => {
      state.f_name = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setF_name } = ContactFormSlice.actions

export default ContactFormSlice.reducer