import { createSlice } from '@reduxjs/toolkit'

export const SelectCodesSlice = createSlice({
  name: 'selectCodes',
  initialState: {
    selectedOption: []
  },
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedOption } = SelectCodesSlice.actions

export default SelectCodesSlice.reducer