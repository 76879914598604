// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form-title {
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.contact-form-container {
  width: 100%;
}

.contact-form-subtext {
  margin-top: 0;
  font-size: 16px;
  color: #6B6B6B;
  font-family: "Lato", sans-serif;
  margin-bottom: 1.5rem;
  padding: 0 5rem;
}

.contact-form-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-input {
  width: 80%;
  padding: 0.25rem 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  min-height: 38px;
  font-size: 16px;
  border: 1px solid;
}

.contact-form-input:hover {
  border: 1px solid #4eabe9;
}

.contact-form-error {
  color: #ff0000;
  font-size: 12px;
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: left;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/StepsSection/ContactForm/ContactForm.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,cAAc;EACd,+BAA+B;EAC/B,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".contact-form-title {\n  margin-bottom: 1rem;\n  font-family: 'Open Sans', sans-serif;\n}\n\n.contact-form-container {\n  width: 100%;\n}\n\n.contact-form-subtext {\n  margin-top: 0;\n  font-size: 16px;\n  color: #6B6B6B;\n  font-family: \"Lato\", sans-serif;\n  margin-bottom: 1.5rem;\n  padding: 0 5rem;\n}\n\n.contact-form-input-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.contact-form-input {\n  width: 80%;\n  padding: 0.25rem 1rem;\n  margin-bottom: 1rem;\n  border-radius: 4px;\n  min-height: 38px;\n  font-size: 16px;\n  border: 1px solid;\n}\n\n.contact-form-input:hover {\n  border: 1px solid #4eabe9;\n}\n\n.contact-form-error {\n  color: #ff0000;\n  font-size: 12px;\n  margin-bottom: 1rem;\n  margin-top: 0;\n  text-align: left;\n  width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
