import aidc from '../../images/IconAIDC.png';
import edwosb from '../../images/iconEDWOSB.png';
import unrestrictedNotSetAside from '../../images/iconUnrestrictedNotSetAside.png';
import wosb from '../../images/iconWOSB.png';
//import iconFastCandidates from '../../images/IconFastCandidates.png';
import iconHubZone from '../../images/IconHubZone.png';
import iconSBSetAside from '../../images/IconSBSetAside.png';
import iconSdvosb from '../../images/IconSdvosb.png';
//import iconTechDoc from '../../images/IconTechDoc.png';

export const CATEGORIES_OPTIONS = [
  {
    code: "AIDC",
    label: "Automated Indefinite Delivery Contract",
    image: aidc,
  },
  {
    code: "SB",
    label: "Small Business Set-Asides",
    image: iconSBSetAside,
  },
  {
    code: "HZ",
    label: "HUBZone Set-Asides",
    image: iconHubZone,
  },
  {
    code: "SVDOSB",
    label: "Service Disabled Veteran Owned Small Business Set-Asides",
    image: iconSdvosb,
  },
  {
    code: "WOSB",
    label: "Woman Owned Small Business Set-Asides",
    image: wosb,
  },
  {
    code: "EDWOSB",
    label: "Economically Disadvantaged Women Owned Small Business Set-Asides",
    image: edwosb,
  },
  {
    code: "UN",
    label: "Unrestricted/Not Set-Asides",
    image: unrestrictedNotSetAside,
  },
  {
    code: "ALL",
    label: "All of the Above – Any and All Solicitations",
    image: null,
  }
]