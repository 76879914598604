import Select from 'react-select';
import { NextButton } from '../../NextButton';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOption } from './SelectCodesSlice';
import { CODE_OPTIONS } from './codeOptions';
import './SelectCodes.css';
import { useState } from 'react';

export const container = () => (styles, state) => ({
  ...styles,
  pointerEvents: "inherit",
  cursor: state.isDisabled ? "not-allowed" : "pointer",
  display: "inherit",
  marginBottom: "20rem",
});

export const control = () => (styles, state) => ({
  ...styles,
  cursor: "pointer",
  color: state.isDisabled ? "#f8f8f8" : "#c8d3d8",
  backgroundColor: state.isDisabled ? "#cccccc" : "#fff",
  pointerEvents: state.isDisabled ? "none" : "inherit",
  borderRadius: "3px",
  borderColor: state.isDisabled
    ? "#c8d3d8"
    : "#4eabe9",
  fontSize: "lato, open-sans, sans-serif",
  transition: "all .2s ease-in-out",
  minHeight: "50px",
  flexWrap: "nowrap",
});

export const input = () => (styles) => {
  return {
    ...styles,
    margin: 0,
  };
};

export const valueContainer = () => (styles) => ({
  ...styles,
  padding:  "8px 16px",
  cursor: "text",
  color: "#c8d3d8",
  fontSize: "16px",
  fontFamily: "lato, open-sans, sans-serif",
  fontWeight: "400",
  lineHeight: "1.5",
  textAlign: "left",
});

const option = () => (styles, state) => ({
  ...styles,
  textAlign: "left",
  cursor: "pointer",
  color: "#282828",
  padding: "0.5rem 1rem",
  display: "flex",
  alignItems: "flex-start",
  ":hover": {
    backgroundColor: "#EBEBEB"
  },
});

const menu = () => (styles) => ({
  ...styles,
  marginTop: "0.5rem",
  width: "auto",
  minWidth: "100%",
});

const multiValue = () => (styles) => ({
  ...styles,
  backgroundColor: "#C5D3D8",
  color: "#c8d3d8",
  cursor: "default",
  borderRadius: "10px",
  border: "1px solid #4eabe9",
  display: "flex",
  alignItems: "center",
  padding: "0.25rem 1.5rem",
  margin: "0.25rem",
  position: "relative",
});

const multiValueRemove = () => (styles) => ({
  ...styles,
  cursor: "pointer",
  position: "absolute",
  // color: "#c8d3d8",
  right: "-4px",
  top: "-7px",
  borderRadius: "20px",
  padding: "0.1rem",
  backgroundColor: "#ff0000",
  color: "#fff",
    
});

const placeholder = () => (styles) => ({
  ...styles,
  color: "#c8d3d8",
  marginLeft: 2,
  marginRight: 0,
  fontSize: "16px",
  fontFamily: "lato, sans-serif",
});


const selectStyles = () => {
  return {
    container: container(),
    control: control(),
    input: input(),
    valueContainer: valueContainer(),
    option: option(),
    menu: menu(),
    multiValue: multiValue(),
    multiValueRemove: multiValueRemove(),
    placeholder: placeholder(),
  };
};

export const SelectCodes = ({ onClickNext }) => {
  const selectedOption = useSelector(state => state.codes.selectedOption);
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Handle change in selection
  const handleChange = (option) => {
    dispatch(setSelectedOption(option));
  };

  return (
    <>
      <h2 className="select-codes-title">Welcome to GovScraper</h2>
      <p className="select-codes-subtext">We provide you DIBBS/DLA Solicitations daily. Directly to your email. Every. Single. Day.</p>
      <p className="select-codes-subtext">The system provides you all the information needed to source and bid on RFQs, saving you
      <span style={{ fontWeight: '900', fontSize: '17.5px' }}> 2 to 3 hours per day</span>.
      </p>
      <p className="select-codes-question">What FSC codes or types of parts would you like to find opportunities for?</p>
      <div className="select-codes-dropdown-container">
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={CODE_OPTIONS}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          styles={selectStyles()}
          isMulti
          placeholder="Select codes"
          // autoFocus={true}
          menuIsOpen={menuIsOpen} // Controlled menu state
          onFocus={() => setMenuIsOpen(true)} // Open the menu when focused
          onBlur={() => setTimeout(() => setMenuIsOpen(false), 100)} // Delay closing to allow click inside
        />
        <NextButton buttonText="Move to Step 2 >" handleClick={onClickNext}
          disabled={selectedOption.length < 1}
        />
      </div>
    </>
  )
}
