// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  margin: 0;
  padding: 0;
  font-family: Lato, "Open Sans", sans-serif;
  background-color: #fff; /* Ensures background consistency */
}

.container {
  max-width: 800px; /* Adjust based on your design preference */
  margin: 0 auto; /* Centers the container */
  text-align: center;
  padding: 50px 0; /* Provides padding at the top and bottom */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,0CAA0C;EAC1C,sBAAsB,EAAE,mCAAmC;AAC7D;;AAEA;EACE,gBAAgB,EAAE,2CAA2C;EAC7D,cAAc,EAAE,0BAA0B;EAC1C,kBAAkB;EAClB,eAAe,EAAE,2CAA2C;AAC9D;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["body, html {\n  margin: 0;\n  padding: 0;\n  font-family: Lato, \"Open Sans\", sans-serif;\n  background-color: #fff; /* Ensures background consistency */\n}\n\n.container {\n  max-width: 800px; /* Adjust based on your design preference */\n  margin: 0 auto; /* Centers the container */\n  text-align: center;\n  padding: 50px 0; /* Provides padding at the top and bottom */\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-height: 60vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
