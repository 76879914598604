import React, { useState } from 'react';
import { NavBar } from './NavBar';
import { SelectedStep } from './StepsSection/SelectedStep';
import './App.css';

function App() {
  const [activeStep, setActiveStep] = useState(1);

  const onClickNext = () => {
    const updatedStep = activeStep <= 5 ? activeStep + 1 : activeStep;
    setActiveStep(updatedStep);
  }

  return (
    <div className="container">
      <NavBar activeStep={activeStep} />
      <div className="content">
        <SelectedStep activeStep={activeStep} onClickNext={onClickNext} />
      </div>
    </div>
  );
}

export default App;
