import { createSlice } from '@reduxjs/toolkit'

export const ResultsSlice = createSlice({
  name: 'results',
  initialState: {
    selectedOption: []
  },
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedOption } = ResultsSlice.actions

export default ResultsSlice.reducer